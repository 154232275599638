import React from "react";
import { Field, useField } from "formik";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { FormHelperText } from "@mui/material";

const FormikCheckboxField = ({ name, label, wrapperProps, ...props }) => {
  const [field, meta] = useField(name);
  return (
    <Box {...wrapperProps}>
      <Box display={"flex"} alignItems={"center"}>
        <Field
          as={FormControlLabel}
          control={<Checkbox checked={field.value} />}
          id={name}
          name={name}
          {...props}
        />
        <Box>{label}</Box>
      </Box>
      {meta.touched && meta.error && (
        <FormHelperText error sx={{ mt: -1, ml: 2 }}>
          {meta.error}
        </FormHelperText>
      )}
    </Box>
  );
};

export default FormikCheckboxField;
