import {useField} from "formik";
import {Box} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {YEAR_MONTH_DAY} from "../../constants";
import FormikTextField from "./FormikTextField";
import React from "react";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Utils from "../../utils/utils";

const FormikDatePickerField = ({ name, label, readOnly,minDate, maxDate, ...rest }) => {
    const [field, _, helpers] = useField(name);
    const { value } = field;
    return (
        <Box {...rest}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label={label}
                    onChange={(pickedValue) =>{
                        helpers.setValue(
                            pickedValue && dayjs(pickedValue).format(YEAR_MONTH_DAY)
                        );
                    }
                    }
                    value={value}
                    inputFormat={"DD.MM.YYYY"}
                    renderInput={(params) => <FormikTextField name={name} {...params} />}
                    readOnly={readOnly}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </LocalizationProvider>
        </Box>
    );
};

export default FormikDatePickerField;
