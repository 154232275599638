import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { sortType, TWO_MINUTES } from "../../../constants";
import { useQuery } from "react-query";
import queryKeys from "../../../api/queryKeys";
import { fetchProducts } from "../../../api";
import SearchTableComponent from "../../shared/SearchTableComponent";
import handleEnterPress from "../../../utils/handleEnterPress";
import handleTableSort from "../../../utils/handleTableSort";
import ProductTable from "./ProductsTable";
import PaginationComponent from "../../shared/PaginationComponent";
import { Box } from "@mui/material";
import TableTitle from "../../shared/TableTitle";

const Products = ({}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState({
    page: 0,
    size: 5,
    searchText: "",
    sortBy: "id",
    sortType: sortType.asc,
  });

  const { data, isLoading } = useQuery(
    [queryKeys.PRODUCTS, query],
    () => fetchProducts(query),
    {staleTime: 0}
  );
  const handleSearch = () => setQuery({ ...query, page: 0, searchText });

  return (
    <div>
      <TableTitle title={t("PRODUCTS")} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <SearchTableComponent
          handleSearch={handleSearch}
          handleChange={(e) => setSearchText(e.target.value)}
          handleKeyDown={(e) => handleEnterPress(e, handleSearch)}
        />
      </Box>
      <ProductTable
        products={data?.data.content}
        query={query}
        loading={isLoading}
        handleSort={(sortBy) => handleTableSort(sortBy, query, setQuery)}
      />
      {data?.data && (
        <PaginationComponent
          totalPages={data.data.totalPages}
          number={data.data.number}
          size={data.data.size}
          setQuery={setQuery}
          query={query}
        />
      )}
    </div>
  );
};

export default Products;
