import {useTranslation} from "react-i18next";
import TableTitle from "../../shared/TableTitle";
import React, {useEffect, useState} from "react";
import ReportTable from "./ReportTable";
import {sortType} from "../../../constants";
import {useQuery} from "react-query";
import queryKeys from "../../../api/queryKeys";
import {fetchReports, refreshReports} from "../../../api";
import handleTableSort from "../../../utils/handleTableSort";
import PaginationComponent from "../../shared/PaginationComponent";
import Button from "@mui/material/Button";
import {Grid} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';

const Reports = () => {
    const {t} = useTranslation();
    const [query, setQuery] = useState({
        page: 0,
        size: 10,
        sortBy: "name",
        sortType: sortType.desc,
    });

    const {data: reports, isLoading, refetch, isRefetching} = useQuery(
        [queryKeys.REPORTS, query],
        () => fetchReports(query),
        { staleTime: 0 }
    );
    function refreshData() {
        refreshReports().finally(() => {
            refetch().finally(() => {})
        });
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <TableTitle title={t("REPORTS")}/>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        endIcon={<RefreshIcon/>}
                        onClick={() => refreshData()}
                    >
                        {t("REFRESH")}
                    </Button>
                </Grid>
            </Grid>
            <ReportTable
                reports={reports?.data.content}
                loading={isLoading || isRefetching}
                query={query}
                handleSort={(sortBy) => handleTableSort(sortBy, query, setQuery)}
            />
            {reports?.data && (
                <PaginationComponent
                    totalPages={reports.data.totalPages}
                    number={reports.data.number}
                    size={reports.data.size}
                    setQuery={setQuery}
                    query={query}
                />
            )}
        </>
    );
};
export default Reports;
