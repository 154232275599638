import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import DividerWithLoading from "../../shared/DividerWithLoading";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCellWithSort from "../../shared/TableCellWithSort";
import TableBody from "@mui/material/TableBody";
import StandardTableCell from "../../shared/StandardTableCell";
import React from "react";
import useCustomersStore from "../../../hooks/useCustomerStore";

const CustomerTable = ({loading,customer,query, handleSort}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { sortType, sortBy } = query;
    const { showCustomerDetails } = useCustomersStore();

    return (
        <>
            <DividerWithLoading loading={loading} marginTop={5}/>
            <TableContainer
                component={Paper}
                sx={{ marginTop: 1 }}
                variant={"outlined"}
            >
                <Table
                    sx={{ width: "100%", cursor: "pointer" }}
                    aria-label="simple table"
                >
                    <TableHead
                        sx={{
                            backgroundColor: theme.palette.secondary.background,
                            color: theme.palette.text.primary,
                        }}
                    >
                        <TableRow>
                            <TableCellWithSort
                                sortOrder={sortType}
                                handleSortClick={() => handleSort("id")}
                                displayedText={t("ID")}
                                selected={sortBy === "id"}
                            />
                            <TableCellWithSort
                                sortOrder={sortType}
                                handleSortClick={() => handleSort("email")}
                                displayedText={t("EMAIL")}
                                selected={sortBy === "name"}
                            />
                            <TableCellWithSort
                                sortOrder={sortType}
                                handleSortClick={() => handleSort("firstName")}
                                displayedText={t("FIRST_NAME")}
                                selected={sortBy === "firstName"}
                            />
                            <TableCellWithSort
                                sortOrder={sortType}
                                handleSortClick={() => handleSort("lastName")}
                                displayedText={t("LAST_NAME")}
                                selected={sortBy === "lastName"}
                            />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customer?.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                onClick={() => showCustomerDetails(row)}
                            >
                                <StandardTableCell content={row.id} />
                                <StandardTableCell content={row.email} />
                                <StandardTableCell content={row.firstName} />
                                <StandardTableCell content={row.lastName} />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
export default CustomerTable;
