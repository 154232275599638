import {useTranslation} from "react-i18next";
import {Box, Checkbox, Chip, Divider, Grid} from "@mui/material";
import {Form, Formik} from "formik";
import FormikTextField from "../../shared/FormikTextField";
import FormikDateTimePickerField from "../../shared/FormikDateTimePickerField";
import React from "react";
import FormikCheckboxField from "../../shared/FormikCheckboxField";

const TicketForm = ({handleSubmit, initialValues}) => {
    const {t} = useTranslation();
    const inputProps = {readOnly: true};
    return (
        <Box pb={2} px={4}>
            <Formik
                initialValues={{
                    canBeValidatedTill: "",
                    created: "",
                    id: 0,
                    isAutoActivation: false,
                    isCancelled: false,
                    isRefunded: false,
                    isValidated: false,
                    product: {
                        id: 0,
                        name: "",
                        sku: ""
                    },
                    receipt: {
                        userId: ""
                    },
                    refundNumber: "",
                    remainingRefundAmount: 0,
                    segment: {
                        arrivalDateTime: "",
                        arrivalStationId: "",
                        arrivalStationName: "",
                        created: "",
                        departureDateTime: "",
                        departureStationId: "",
                        departureStationName: "",
                        id: "",
                        price: 0,
                        priceNet: "",
                    },
                    ticketNumber: "",
                    userRefundPermitted: false,
                    validFrom: "",
                    validTill: "",
                    ...initialValues,
                }}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                <Form>
                    <Grid container spacing={1}>
                        <Grid item md={3}>
                            <FormikTextField
                                name={"ticketNumber"}
                                label={`${t("TICKET_NUMBER")}`}
                                {...{inputProps}}
                            />
                        </Grid>
                        <Grid item md={5}>
                            <FormikTextField
                                name={"receipt.userId"}
                                label={`${t("USER_ID")}`}
                                {...{inputProps}}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <FormikDateTimePickerField
                                name={"created"}
                                label={`${t("CREATED")}`}
                                readOnly
                            />
                        </Grid>
                        <Grid item md={3}>
                            <FormikCheckboxField
                                name={"isAutoActivation"}
                                label={`${t("IS_AUTO_ACTIVATION")}`}
                                disabled
                            />
                        </Grid>
                        <Grid item md={3}>
                            <FormikCheckboxField
                                name={"isValidated"}
                                label={`${t("IS_VALIDATED")}`}
                                disabled
                            />
                        </Grid>
                        <Grid item md={3}>
                            <FormikCheckboxField
                                name={"isCancelled"}
                                label={`${t("IS_CANCELLED")}`}
                                disabled
                            />
                        </Grid>
                        <Grid item md={4}>
                            <FormikDateTimePickerField
                                name={"validFrom"}
                                label={`${t("VALID_FROM")}`}
                                readOnly
                            />
                        </Grid>
                        <Grid item md={4}>
                            <FormikDateTimePickerField
                                name={"validTill"}
                                label={`${t("VALID_TO")}`}
                                readOnly
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Divider>
                                <Chip label={t("PRODUCT")}/>
                            </Divider>
                        </Grid>
                        <Grid item md={12}>
                            <FormikTextField
                                name={"product.name"}
                                label={`${t("NAME")}`}
                                {...{inputProps}}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <FormikTextField
                                name={"product.sku"}
                                label={`${t("SKU")}`}
                                {...{inputProps}}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Divider>
                                <Chip label={t("SEGMENT")}/>
                            </Divider>
                        </Grid>
                        <Grid item md={3}>
                            <FormikTextField
                                name={"segment.arrivalStationId"}
                                label={`${t("ARRIVAL_STATION_ID")}`}
                                {...{inputProps}}
                            />
                        </Grid>
                        <Grid item md={5}>
                            <FormikTextField
                                name={"segment.arrivalStationName"}
                                label={`${t("ARRIVAL_STATION_NAME")}`}
                                {...{inputProps}}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <FormikDateTimePickerField
                                name={"segment.arrivalDateTime"}
                                label={`${t("ARRIVAL_STATION_TIME")}`}
                                readOnly
                            />
                        </Grid>
                        <Grid item md={3}>
                            <FormikTextField
                                name={"segment.departureStationId"}
                                label={`${t("DEPARTURE_STATION_ID")}`}
                                {...{inputProps}}
                            />
                        </Grid>
                        <Grid item md={5}>
                            <FormikTextField
                                name={"segment.departureStationName"}
                                label={`${t("DEPARTURE_STATION_NAME")}`}
                                {...{inputProps}}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <FormikDateTimePickerField
                                name={"segment.departureDateTime"}
                                label={`${t("DEPARTURE_STATION_TIME")}`}
                                readOnly
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Divider>
                                <Chip label={t("REFUND")}/>
                            </Divider>
                        </Grid>
                        <Grid item md={2}>
                            <FormikCheckboxField
                                name={"isRefunded"}
                                label={`${t("IS_REFUNDED")}`}
                                disabled
                            />
                        </Grid>
                        <Grid item md={9}>
                            <FormikCheckboxField
                                name={"userRefundPermitted"}
                                label={`${t("USER_REFUND_PERMITTED")}`}
                                disabled
                            />
                        </Grid>
                        <Grid item md={4}>
                            <FormikTextField
                                name={"remainingRefundAmount"}
                                label={`${t("REFUND_AMOUNT")}`}
                                {...{inputProps}}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <FormikTextField
                                name={"refundNumber"}
                                label={`${t("REFUND_NUMBER")}`}
                                {...{inputProps}}
                            />
                        </Grid>
                    </Grid>
                    <Box py={0.5}/>
                </Form>
            </Formik>
        </Box>
    );
};
export default TicketForm;
