import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import DividerWithLoading from "../../shared/DividerWithLoading";
import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCellWithSort from "../../shared/TableCellWithSort";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import StandardTableCell from "../../shared/StandardTableCell";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/Download';
import {useQuery} from "react-query";
import queryKeys from "../../../api/queryKeys";
import {fetchReportByName, fetchReports} from "../../../api";
import {TWO_MINUTES} from "../../../constants";

const ReportTable = ({ loading, reports, query, handleSort }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { sortType, sortBy } = query;

    function getReportByName(fileName) {
        let result = fetchReportByName(fileName);
        result.then(res => {
            var url = window.URL.createObjectURL(res.data);
            var a = document.createElement('a');
            a.href = url;
            a.download = (fileName);
            document.body.appendChild(a);
            a.click();
            a.remove();
        })
    }

    return (
        <>
            <DividerWithLoading loading={loading} marginTop={5}/>
            <TableContainer
                component={Paper}
                sx={{ marginTop: 1 }}
                variant={"outlined"}
            >
                <Table
                    sx={{ width: "100%", cursor: "pointer" }}
                    aria-label="simple table"
                >
                    <TableHead
                        sx={{
                            backgroundColor: theme.palette.secondary.background,
                            color: theme.palette.text.primary,
                        }}
                    >
                        <TableRow>
                            <TableCellWithSort
                                sortOrder={sortType}
                                handleSortClick={() => handleSort("name")}
                                displayedText={t("NAME")}
                                selected={sortBy === "name"}
                                width={'30%'}
                            />
                            <TableCellWithSort
                                sortOrder={sortType}
                                handleSortClick={() => handleSort("date")}
                                displayedText={t("PERIOD")}
                                selected={sortBy === "date"}
                            />
                            <TableCell align="center"></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reports?.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <StandardTableCell content={row.fileName} />
                                <StandardTableCell content={row.period} />
                                <TableCell align="center">
                                    <IconButton aria-label="delete" size="large">
                                        <DownloadIcon
                                            onClick={() => getReportByName(row.fileName)}
                                        />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
export default ReportTable;
