export const sortType = {
  asc: "ASC",
  desc: "DESC",
};
export const check = true

export const TWO_MINUTES = 60 * 2000;
export const TEN_MINUTES = 60 * 10000;

export const YEAR_MONTH_DAY = "YYYY-MM-DD";

export const DAY_MONTH_YEAR = "DD-MM-YYYY";

export const DAT_DOT_MONTH_DOT_YEAR = "DD.MM.YYYY";

export const DAY_MONTH_YEAR_HOURS_MINUTES_SECONDS = "DD.MM.YYYY HH:mm:ss";

export const PAGINATION_SIZES = [5, 10, 25, 50, 100];

export const numberPattern = /^[0-9]+$/;
