import React from "react";
import DividerWithLoading from "../../shared/DividerWithLoading";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StandardTableCell from "../../shared/StandardTableCell";
import TableBody from "@mui/material/TableBody";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import TableCellWithSort from "../../shared/TableCellWithSort";
import EditUserModal from "./EditUserModal";
import { useState } from "react";
import useTokensState from "../../../hooks/useTokensState";

const UsersTable = ({ loading, users, query, handleSort }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [userBeingEdited, setUserBeingEdited] = useState(undefined);
  const { sortType, sortBy } = query;
    const {getRole} = useTokensState();
  return (
    <>
      <DividerWithLoading loading={loading} marginTop={5}/>
      <TableContainer
        component={Paper}
        sx={{ marginTop: 1 }}
        variant={"outlined"}
      >
        <Table
          sx={{ width: "100%", cursor: "pointer" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              backgroundColor: theme.palette.secondary.background,
              color: theme.palette.text.primary,
            }}
          >
            <TableRow>
              <TableCellWithSort
                sortOrder={sortType}
                handleSortClick={() => handleSort("id")}
                displayedText={t("ID")}
                selected={sortBy === "id"}
              />
              <TableCellWithSort
                sortOrder={sortType}
                handleSortClick={() => handleSort("firstName")}
                displayedText={t("FIRST_NAME")}
                selected={sortBy === "firstName"}
              />
              <TableCellWithSort
                sortOrder={sortType}
                handleSortClick={() => handleSort("lastName")}
                displayedText={t("LAST_NAME")}
                selected={sortBy === "lastName"}
              />
              <TableCellWithSort
                sortOrder={sortType}
                handleSortClick={() => handleSort("email")}
                displayedText={t("EMAIL")}
                selected={sortBy === "email"}
              />
              <StandardTableCell content={t("ROLES")} />
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={getRole() !== 'READ_ONLY' ? ()=>
                  setUserBeingEdited({
                    firstName: row.firstName,
                    lastName: row.lastName,
                    email: row.email,
                    role: row.role,
                    id: row.id,
                  }) : null
                }
              >
                <StandardTableCell content={row.id} />
                <StandardTableCell content={row.firstName} />
                <StandardTableCell content={row.lastName} />
                <StandardTableCell content={row.email} />
                <StandardTableCell content={row.role} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditUserModal
        open={!!userBeingEdited}
        onClose={() => setUserBeingEdited(undefined)}
        userBeingEdited={userBeingEdited}
      />
    </>
  );
};

export default UsersTable;
