import axios from "axios";
import environment from "../assets/environment.json";
import i18next from "i18next";
//TODO move all api-related stuff here (endpoints + intenceptors from Loader)

export const api = axios.create({
    baseURL: environment.backendURL,
});
export const apiToken = axios.create({
    baseURL: environment.backendURL,
});
api.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
api.defaults.withCredentials = true;
apiToken.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
apiToken.defaults.withCredentials = true;

api.interceptors.request.use(
    function (config) {
        console.log('request')
        let token = localStorage.getItem('token');
        config.headers["Accept-Language"] = i18next.language;
        if (token !== null) {
            config.headers["Authorization"] = `Bearer ${token}`;
        } else {
            let refreshToken = localStorage.getItem('refreshToken');
            refreshTokenApi(refreshToken).then(res => {
                console.log('jestem w refresh token w request')
                console.log(res)
                localStorage.setItem(
                    'token',
                    res.data.accessToken
                );
                localStorage.setItem(
                    'refreshToken',
                    res.data.refreshToken
                );
                config.headers["Authorization"] = `Bearer ${res.data.accessToken}`;
                location.reload()
            }).catch(reason => {
                if (401 === reason?.response?.status){
                    console.log('wylogowanie bo błędny refreshToken w response')
                    localStorage.clear();
                    location.reload();
                }
            })
        }
        config.withCredentials = true;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
api.interceptors.response.use(function (response) {
    return response;
}, (error) => {
    console.log(error)
    let refreshToken = localStorage.getItem('refreshToken');
    let token = localStorage.getItem('token');
    if (401 === error.response.status && token){
        console.log('regeneracja tokena w response')
        refreshTokenApi(refreshToken).then(res => {
            console.log('jestem w refresh token')
            localStorage.setItem(
                'token',
                res.data.accessToken
            );
            localStorage.setItem(
                'refreshToken',
                res.data.refreshToken
            );
        }).catch(reason => {
            if (401 === reason?.response?.status){
                console.log('wylogowanie bo błędny refreshToken w response')
                localStorage.clear();
                location.reload();
            }
        })
        return Promise.reject(error);
    }
});


export const refreshTokenApi = (refreshToken) =>
  apiToken.post("/auth/refresh", {
    refreshToken,
  });

export const loginApi = (login, password) =>
  api.post("/auth/login", {
    login,
    password,
  });

export const logout = () => api.post("/auth/logout");

export const resetEmailPassword = (email) =>
  api.post(`/admin-user/password/email-reset`, {email: email});

export const fetchUsers = (query) =>
  api.get("/admin-user", {
    params: query,
  });

export const fetchUserRoles = () => api.get("/admin-user/roles");

export const addUser = (body) => api.post("/admin-user", body);

export const changePassword = (body) =>
  api.post("/admin-user/password/change", body);

export const editUser = (body, userId) =>
  api.put("/admin-user/" + userId, body);

export const resetPassword = (id) =>
  api.post("/admin-user/password/id-reset/" + id);

export const fetchProducts = (query) => {
  return api.get("/product", {
    params: query,
  });
};

export const fetchAppInfo = () => api.get("/version");

export const fetchConfiguration = (query) => {
  return api.get("/configuration", {
    params: query,
  });
};
export const addConfiguration = (body) => api.post("/configuration", body);

export const editConfiguration = (body) =>
  api.put("/configuration/" + body.id, body);

export const deleteConfiguration = (id) => api.delete(`/configuration/${id}`);

export const fetchCharts = (query) => {
    return api.get('/charts', {
        params: query,
    })};
export const fetchTicket = (query) => {
    return api.get("/ticket", {
        params: query,
    });
};

export const fetchCustomer = (query) => {
    return api.get("/user", {
        params: query,
    });
};
export const fetchCustomerById = (id) => {
    return api.get("/user/" + id);
};
export const fetchChartsPeriods = () => api.get('/charts/period');
export const fetchReports = (query) => {
    return api.get("/reports", {
        params: query,
    });
};
export const refreshReports = () => api.delete('/reports/cache');
export const fetchReportByName = (name) => {
    return api.get("/reports/download/" + name, {responseType: "blob"});
};

