import React from "react";
import { Box, Paper, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Paper elevation={20}>
        <div
          style={{
            backgroundColor: theme.palette.secondary.light,
            textAlign: "center",
            paddingBottom: "80px",
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            style={{ width: "100%", paddingTop: 60, flexDirection: "column" }}
          >
            <Typography
              style={{ fontSize: 14 }}
              color={theme.palette.text.secondary}
            >
              {t("BMT_ADMIN_PANEL")}
            </Typography>
          </Box>
        </div>
      </Paper>
    </>
  );
}
