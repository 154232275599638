import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import FormikTextField from "../../shared/FormikTextField";
import { Grid, Box } from "@mui/material";
import FormikDateTimePickerField from "../../shared/FormikDateTimePickerField";
import { useProductFormValidationSchema } from "./validation";

const ProductForm = ({ handleSubmit, initialValues }) => {
  const { t } = useTranslation();
  const validationSchema = useProductFormValidationSchema();
  const inputProps = { readOnly: true };
console.log(initialValues)
  return (
    <Box pb={2} px={4}>
      <Formik
        initialValues={{
          sku: "",
          price: 0,
          priceNet: 0,
          currency: "",
          taxClass: "",
          taxClassCode: 0,
          ticketValidityAmountPeople: "",
          name: "",
          description: "",
          shortDescription: "",
          validityText: "",
          soldText: "",
          productScheduleStartDate: "",
          productScheduleEndDate: "",
          productValidityType: "",
          productValidity: "",
          productDurationType: "",
          language: "",
          ...initialValues,
        }}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        <Form>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <FormikTextField
                name={"sku"}
                label={`${t("SKU")}`}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={12}>
              <FormikTextField
                name={"name"}
                label={`${t("NAME")}`}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={12}>
              <FormikTextField
                name={"description"}
                label={`${t("DESCRIPTION")}`}
                maxRows={6}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={12}>
              <FormikTextField
                name={"validityText"}
                label={`${t("validityText")}`}
                maxRows={3}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={12}>
              <FormikTextField
                name={"shortDescription"}
                label={`${t("shortDescription")}`}
                maxRows={3}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={12}>
              <FormikTextField
                name={"soldText"}
                label={`${t("soldText")}`}
                maxRows={3}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={6}>
              <FormikDateTimePickerField
                name={"productScheduleStartDate"}
                label={`${t("productScheduleStartDate")}`}
                readOnly
              />
            </Grid>
            <Grid item md={6}>
              <FormikDateTimePickerField
                name={"productScheduleEndDate"}
                label={`${t("productScheduleEndDate")}`}
                readOnly
              />
            </Grid>
            <Grid item md={2}>
              <FormikTextField
                name={"price"}
                label={`${t("price")}`}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={2}>
              <FormikTextField
                name={"priceNet"}
                label={`${t("priceNet")}`}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={2}>
              <FormikTextField
                name={"currency"}
                label={`${t("currency")}`}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={2}>
              <FormikTextField
                name={"taxClass"}
                label={`${t("taxClass")}`}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={2}>
              <FormikTextField
                name={"taxClassCode"}
                label={`${t("taxClassCode")}`}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={2}>
              <FormikTextField
                name={"ticketValidityAmountPeople"}
                label={`${t("ticketValidityAmountPeople")}`}
                {...{ inputProps }}
              />
            </Grid>

            <Grid item md={2}>
              <FormikTextField
                name={"productValidityType"}
                label={`${t("productValidityType")}`}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={2}>
              <FormikTextField
                name={"productValidity"}
                label={`${t("productValidity")}`}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={2}>
              <FormikTextField
                name={"productDurationType"}
                label={`${t("productDurationType")}`}
                {...{ inputProps }}
              />
            </Grid>
            <Grid item md={2}>
              <FormikTextField
                name={"language"}
                label={`${t("language")}`}
                {...{ inputProps }}
              />
            </Grid>
          </Grid>
          <Box py={0.5} />
        </Form>
      </Formik>
    </Box>
  );
};
export default ProductForm;
