import { Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { useTranslation } from "react-i18next";

const SearchTableComponent = ({
  handleSearch,
  handleChange,
  handleKeyDown,
}) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={8}>
      <TextField
        onChange={handleChange}
        id="outlined-basic"
        name="searchText"
        label={t("SEARCH")}
        variant="outlined"
        color="secondary"
        onKeyDown={(e) => handleKeyDown(e, handleSearch)}
      />
      <Button
        variant="contained"
        color="secondary"
        size="large"
        style={{ marginTop: 6, marginLeft: 5 }}
        onClick={handleSearch}
      >
        {t("SEARCH")}
      </Button>
    </Grid>
  );
};

export default SearchTableComponent;
