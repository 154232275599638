const queryKeys = {
  USERS: "users",
  LANGUAGES: "languages",
  PRODUCTS: "products",
  PRODUCT: "product",
  APP_INFO: "appInfo",
  ALLOWED_ROLES: "allowedRoles",
  CONFIGURATION: "configuration ",
  CHARTS: "charts",
  CHARTS_PERIOD: "chartsPeriod",
  TICKETS:"tickets",
  CUSTOMER:"customer",
  REPORTS:"Reports"
};

export default queryKeys;
