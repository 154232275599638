import React, { useState } from "react";
import DividerWithLoading from "../../shared/DividerWithLoading";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StandardTableCell from "../../shared/StandardTableCell";
import TableBody from "@mui/material/TableBody";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import TableCellWithSort from "../../shared/TableCellWithSort";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EditConfigurationModal from "./EditConfigurationModal";

const ConfigurationTable = ({ loading, configuration, query, handleSort }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { sortType, sortBy } = query;
  const [configurationBeingEdited, setConfigurationBeingEdited] =
    useState(undefined);
  return (
    <>
      <DividerWithLoading loading={loading} marginTop={5}/>
      <TableContainer
        component={Paper}
        sx={{ marginTop: 1 }}
        variant={"outlined"}
      >
        <Table
          sx={{ width: "100%", cursor: "pointer" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              backgroundColor: theme.palette.secondary.background,
              color: theme.palette.text.primary,
            }}
          >
            <TableRow>
              <TableCellWithSort
                sortOrder={sortType}
                handleSortClick={() => handleSort("id")}
                displayedText={t("ID")}
                selected={sortBy === "id"}
              />
              <TableCellWithSort
                sortOrder={sortType}
                handleSortClick={() => handleSort("name")}
                displayedText={t("NAME")}
                selected={sortBy === "name"}
              />
              <TableCellWithSort
                sortOrder={sortType}
                handleSortClick={() => handleSort("value")}
                displayedText={t("VALUE")}
                selected={sortBy === "value"}
              />
              <TableCellWithSort
                sortOrder={sortType}
                handleSortClick={() => handleSort("description")}
                displayedText={t("DESCRIPTION")}
                selected={sortBy === "description"}
              />
              <TableCellWithSort
                sortOrder={sortType}
                handleSortClick={() => handleSort("required")}
                displayedText={t("REQUIRED")}
                selected={sortBy === "required"}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {configuration?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => setConfigurationBeingEdited(row)}
              >
                <StandardTableCell content={row.id} />
                <StandardTableCell content={row.name} />
                <StandardTableCell
                  content={
                    row.value.length > 100 ? (
                      <>
                        {row.value.substring(0, 100)} ... +{" "}
                        <b style={{ color: "lightgray" }}>
                          more({row.value.length - 100}){" "}
                        </b>
                      </>
                    ) : (
                      row.value
                    )
                  }
                />
                <StandardTableCell content={row.description} />
                <StandardTableCell
                  content={
                    row.required ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )
                  }
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditConfigurationModal
        open={!!configurationBeingEdited}
        onClose={() => setConfigurationBeingEdited(undefined)}
        configurationBeingEdited={configurationBeingEdited}
      />
    </>
  );
};

export default ConfigurationTable;
