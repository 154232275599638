import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import DividerWithLoading from "../../shared/DividerWithLoading";
import React, {useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCellWithSort from "../../shared/TableCellWithSort";
import TableBody from "@mui/material/TableBody";
import StandardTableCell from "../../shared/StandardTableCell";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import TableCell from "@mui/material/TableCell";
import useCustomersStore from "../../../hooks/useCustomerStore";
import {fetchCustomerById} from "../../../api";
import {useHistory} from "react-router-dom";
import {Button} from "@mui/material";
import EditProductModal from "../products/EditProductModal";
import EditTicketModal from "./EditTicketModal";

const TicketTable = ({loading, ticket,query,handleSort, isShowingInTicket}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { showCustomerDetails } = useCustomersStore();
    const { pageType: customersPageType } = useCustomersStore();
    const { sortType, sortBy } = query;
    const history = useHistory();
    const [ticketBeingEdited, setTicketBeingEdited] = useState(undefined);


    function handleShowCustomerDetails(id) {
        if (id !== undefined && id !== null){
            fetchCustomerById(id).then(customer => {
                showCustomerDetails(customer.data)
                if (isShowingInTicket){
                    history.push("/admin-panel?tab=4");
            }});
        }
    }

    return (
        <>
            <DividerWithLoading loading={loading} marginTop={5}/>
            <TableContainer
                component={Paper}
                sx={{ marginTop: 1 }}
                variant={"outlined"}
            >
                <Table
                    sx={{ width: "100%", cursor: "pointer" }}
                    aria-label="simple table"
                >
                    <TableHead
                        sx={{
                            backgroundColor: theme.palette.secondary.background,
                            color: theme.palette.text.primary,
                        }}
                    >
                        <TableRow
                            style={{cursor: "default"}}
                        >
                            <TableCellWithSort
                                style={{cursor: "pointer"}}
                                sortOrder={sortType}
                                handleSortClick={() => handleSort("id")}
                                displayedText={t("ID")}
                                selected={sortBy === "id"}
                            />
                            <TableCell align="center">{t("USER_ID")}</TableCell>
                            <TableCell align="center">{t("PRODUCT")}</TableCell>
                            <TableCell align="center">{t("TICKET_NUMBER")}</TableCell>
                            <TableCell align="center">{t("REFUND_NUMBER")}</TableCell>
                            <TableCell align="center">{t("REFUND_AMOUNT")}</TableCell>
                            <TableCell align="center">{t("IS_CANCELLED")}</TableCell>
                            <TableCell align="center">{t("IS_VALIDATED")}</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ticket?.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 }}}
                                style={{cursor: "default"}}
                            >
                                <TableCell align="center">{row.id}</TableCell>
                                <TableCell
                                    style={{cursor: "pointer"}}
                                    onClick={() => handleShowCustomerDetails(row.receipt?.userId)}
                                >{row.receipt?.userId}</TableCell>
                                <TableCell align="center">{row.product?.name + " (" + row.product?.id + ")"}</TableCell>
                                <TableCell align="center">{row.ticketNumber}</TableCell>
                                <TableCell align="center">{row.refundNumber}</TableCell>
                                <TableCell align="center">{t("AMOUNT_PATTERN", {val: row.remainingRefundAmount})}</TableCell>
                                <TableCell align="center">
                                    {
                                        row.isCancelled ? (
                                            <CheckBoxIcon />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon />
                                        )
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        row.isValidated ? (
                                            <CheckBoxIcon />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon />
                                        )
                                    }
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        onClick={(e) => setTicketBeingEdited(row)}
                                    >
                                        {t("DETAILS")}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <EditTicketModal
                open={!!ticketBeingEdited}
                onClose={() => setTicketBeingEdited(undefined)}
                ticketBeingEdited={ticketBeingEdited}
            />
        </>
    );
};
export default TicketTable;
