import React from "react";
import { useField } from "formik";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import FormikTextField from "./FormikTextField";
import { Box } from "@mui/material";
import { YEAR_MONTH_DAY } from "../../constants";

const FormikDateTimePickerField = ({ name, label, readOnly, ...rest }) => {
  const [field, _, helpers] = useField(name);
  const { value } = field;
  return (
    <Box {...rest}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label={label}
          onChange={(pickedValue) =>
            helpers.setValue(
              pickedValue && dayjs(pickedValue).format(YEAR_MONTH_DAY)
            )
          }
          inputFormat={"DD.MM.YYYY HH:mm:ss"}
          value={value}
          renderInput={(params) => <FormikTextField name={name} {...params} />}
          readOnly={readOnly}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default FormikDateTimePickerField;
