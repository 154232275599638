import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import DividerWithLoading from "../../shared/DividerWithLoading";
import React, { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCellWithSort from "../../shared/TableCellWithSort";
import StandardTableCell from "../../shared/StandardTableCell";
import TableBody from "@mui/material/TableBody";
import EditProductModal from "./EditProductModal";

const ProductTable = ({ loading, products, query, handleSort }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [productBeingEdited, setProductBeingEdited] = useState(undefined);
  const { sortType, sortBy } = query;

  return (
    <>
      <DividerWithLoading loading={loading} marginTop={5}/>
      <TableContainer
        component={Paper}
        sx={{ marginTop: 1 }}
        variant={"outlined"}
      >
        <Table
          sx={{ width: "100%", cursor: "pointer" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              backgroundColor: theme.palette.secondary.background,
              color: theme.palette.text.primary,
            }}
          >
            <TableRow>
              <TableCellWithSort
                sortOrder={sortType}
                handleSortClick={() => handleSort("id")}
                displayedText={t("ID")}
                selected={sortBy === "id"}
              />
              <TableCellWithSort
                sortOrder={sortType}
                handleSortClick={() => handleSort("name")}
                displayedText={t("NAME")}
                selected={sortBy === "name"}
              />
              <TableCellWithSort
                sortOrder={sortType}
                handleSortClick={() => handleSort("sku")}
                displayedText={t("SKU")}
                selected={sortBy === "sku"}
              />
              <TableCellWithSort
                sortOrder={sortType}
                handleSortClick={() => handleSort("price")}
                displayedText={t("price")}
                selected={sortBy === "price"}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {products?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => setProductBeingEdited(row)}
              >
                <StandardTableCell content={row.id} />
                <StandardTableCell content={row.name} />
                <StandardTableCell content={row.sku} />
                <StandardTableCell content={row.price + " " + row.currency} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditProductModal
        open={!!productBeingEdited}
        onClose={() => setProductBeingEdited(undefined)}
        productBeingEdited={productBeingEdited}
      />
    </>
  );
};
export default ProductTable;
